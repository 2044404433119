import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { OptionalEmojiDescription } from '@atlaskit/emoji';

import { getApolloClient } from '@confluence/graphql';

import { getEmojiId } from './transformer';
import { EMOJI_TITLE_DRAFT_FIELD } from './useEmojiStatusFieldName';
import { getEmojiTitleProperty } from './emoji-title/EmojiTitleQuery.graphql';

export const sendAnalyticsEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{
		eventType,
		element,
		action,
		actionSubject,
		actionSubjectId,
		attributes = {},
	}: {
		eventType: string;
		element: string;
		action: string;
		actionSubject: string;
		actionSubjectId: string;
		attributes?: Record<string, string | null | undefined | boolean>;
	},
) => {
	createAnalyticsEvent({
		type: eventType,
		data: {
			action,
			source: 'EmojiTitleSection',
			actionSubject,
			actionSubjectId,
			attributes: {
				element,
				...attributes,
			},
		},
	}).fire();
};

export const trackDraftCreatedWithEmoji = async ({
	createAnalyticsEvent,
	contentId,
	isLivePage,
}: {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	contentId: string;
	isLivePage: boolean;
}) => {
	const { data } = await getApolloClient().query({
		query: getEmojiTitleProperty,
		variables: {
			contentId,
			status: ['current', 'draft'],
			skipPublishedFragment: false,
		},
	});

	const emojiId = getEmojiId(data, EMOJI_TITLE_DRAFT_FIELD);
	const hasDraftEmoji = emojiId.length >= 0 && emojiId !== '';

	// only track draft with emoji
	if (hasDraftEmoji) {
		sendAnalyticsEvent(createAnalyticsEvent, {
			eventType: 'sendTrackEvent',
			element: '',
			action: 'created',
			actionSubject: 'draftWithEmoji',
			actionSubjectId: '',
			attributes: {
				emojiId,
				isLivePage,
			},
		});
	}
};

export const onFailedToLoadEmoji = ({
	createAnalyticsEvent,
	emoji,
	context,
	error = {
		message: '',
	},
	isLivePage,
}: {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	emoji: OptionalEmojiDescription;
	context: string;
	error?: {
		message: string;
	};
	isLivePage?: boolean;
}) => {
	sendAnalyticsEvent(createAnalyticsEvent, {
		eventType: 'sendTrackEvent',
		element: 'emoji',
		action: 'error',
		actionSubject: 'renderEmoji',
		actionSubjectId: 'renderEmojiError',
		attributes: {
			emojiShortName: emoji && emoji.shortName,
			emojiId: emoji && emoji.id,
			context,
			errorMessage: error.message,
			isLivePage,
		},
	});
};
