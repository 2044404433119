import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';

import QuotationMarkIcon from '@atlaskit/icon/core/quotation-mark';

import {
	ExpandableMenuItem,
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
} from '@atlassian/navigation-system/side-nav/expandable-menu-item';

import { SpacePersistentCollapsibleSectionLoader } from '@confluence/collapsible-section/entry-points/SpacePersistentCollapsibleSectionLoader';
import { useSpacePersistentExpansion } from '@confluence/collapsible-section/entry-points/useSpacePersistentExpansion';
import { CreateBlogButton } from '@confluence/contextual-create';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { useContentType } from '@confluence/page-context';

import { BlogTreeRootComponent } from './BlogTreeRootComponent';

type BlogTreeProps = {
	spaceKey: string;
	pageTreeFinishedLoading: boolean;
};

const i18n = defineMessages({
	blogsTitle: {
		id: 'blog-tree.section.header.title',
		defaultMessage: 'Blogs',
		description: 'Title of Blog tree section in space side navigation',
	},
	blogsTooltip: {
		id: 'blog-tree.section.header.tooltip',
		defaultMessage: 'View all blogs in this space',
		description: 'Tooltip message on hover of Blogs section header',
	},
});

export const BlogTree: FC<BlogTreeProps> = ({ spaceKey, pageTreeFinishedLoading }) => {
	const [pageContextContentType] = useContentType();
	const { formatMessage } = useIntl();
	const isNav4Enabled = useIsNav4Enabled();
	const forceExpandBecauseViewingBlog = pageContextContentType === 'blogpost';
	const { isExpanded, onExpansionToggle } = useSpacePersistentExpansion({
		sectionName: 'blogTree',
		collapsedByDefault: !forceExpandBecauseViewingBlog,
		forceExpand: forceExpandBecauseViewingBlog,
	});

	return (
		<ErrorBoundary attribution={Attribution.DISCO}>
			{isNav4Enabled ? (
				<ExpandableMenuItem isExpanded={isExpanded} onExpansionToggle={onExpansionToggle}>
					<ExpandableMenuItemTrigger
						elemBefore={<QuotationMarkIcon label="" color="currentColor" />}
						actionsOnHover={<CreateBlogButton spaceKey={spaceKey} />}
					>
						<FormattedMessage {...i18n.blogsTitle} />
					</ExpandableMenuItemTrigger>
					<ExpandableMenuItemContent>
						<BlogTreeRootComponent
							spaceKey={spaceKey}
							pageTreeFinishedLoading={pageTreeFinishedLoading}
							isNav4Enabled={isNav4Enabled}
						/>
					</ExpandableMenuItemContent>
				</ExpandableMenuItem>
			) : (
				<SpacePersistentCollapsibleSectionLoader
					renderAfterIconButton={() => <CreateBlogButton spaceKey={spaceKey} />}
					headerTitle={formatMessage(i18n.blogsTitle)}
					sectionName="blogTree"
					tooltipContent={formatMessage(i18n.blogsTooltip)}
					collapsedByDefault={!forceExpandBecauseViewingBlog}
				>
					<BlogTreeRootComponent
						spaceKey={spaceKey}
						pageTreeFinishedLoading={pageTreeFinishedLoading}
					/>
				</SpacePersistentCollapsibleSectionLoader>
			)}
		</ErrorBoundary>
	);
};
