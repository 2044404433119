import { useCallback, useEffect, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

class OptimisticTitleChangeEventBus {
	private listeners: Record<string, ((title: string) => void)[]>;

	constructor() {
		this.listeners = {};
	}

	emit(contentId: string, title: string) {
		if (this.listeners[contentId]) {
			this.listeners[contentId].forEach((fn) => {
				fn(title);
			});
		}
	}

	subscribe(contentId: string, fn: (title: string) => void) {
		if (!this.listeners[contentId]) {
			this.listeners[contentId] = [];
		}

		this.listeners[contentId].push(fn);
	}

	unsubcribe(contentId: string, fn: (title: string) => void) {
		if (this.listeners[contentId]) {
			const index = this.listeners[contentId].findIndex((item) => item[0] === fn);
			if (index !== -1) {
				this.listeners[contentId].splice(index, 1);
			}
		}
	}
}

type OptimisticTitleChangeData = {
	optimisticTitle: string | null;
	setOptimisticTitle: (title: string) => void;
};

const optimisticTitleChangeEventBus = new OptimisticTitleChangeEventBus();

export const useOptimisticTitleChange = (
	contentId: string,
	initialTitle: string | null,
	onOptimisticUpdate?: (contentId: string, title: string) => void,
): OptimisticTitleChangeData => {
	const [optimisticTitle, setOptimisticTitle] = useState(initialTitle);

	const publishOptimisticTitle = useCallback(
		(value: string) => {
			if (value !== optimisticTitle) {
				optimisticTitleChangeEventBus && optimisticTitleChangeEventBus.emit(contentId, value);
			}
		},
		[contentId, optimisticTitle],
	);

	const handleTitleChanged = useCallback(
		(title: string) => {
			// Only set state when the FG is enabled to avoid triggering unnecessary re-renders.
			if (fg('confluence_optimistic_pagetree_title_update')) {
				setOptimisticTitle(title);
				onOptimisticUpdate && onOptimisticUpdate(contentId, title);
			}
		},
		[contentId, setOptimisticTitle, onOptimisticUpdate],
	);

	useEffect(() => {
		optimisticTitleChangeEventBus &&
			optimisticTitleChangeEventBus.subscribe(contentId, handleTitleChanged);

		return () => {
			optimisticTitleChangeEventBus &&
				optimisticTitleChangeEventBus.unsubcribe(contentId, handleTitleChanged);
		};
	}, [contentId, handleTitleChanged]);

	// If initialTitle changes, update optimistic title to match new initialTitle.
	// Why? This fixes scenarios where the BE title of the page changes without the title changing on the FE, e.g. when a draft with no title is converted to a live page with a title generated by the BE.
	//      Without this useEffect, the title will undesirably stay as the original initialTitle of "Untitled" instead of using the new title from the BE.
	useEffect(() => {
		// Only set state when the FG is enabled to avoid triggering unnecessary re-renders.
		if (fg('confluence_optimistic_pagetree_title_update')) {
			setOptimisticTitle(initialTitle);
		}
	}, [initialTitle]);

	// If feature flag is off, this hook should behave as a no-op
	// (always return initial title, and set should do nothing)
	return fg('confluence_optimistic_pagetree_title_update')
		? {
				optimisticTitle,
				setOptimisticTitle: publishOptimisticTitle,
			}
		: {
				optimisticTitle: initialTitle,
				setOptimisticTitle: (_: string | null) => {},
			};
};
