import React, { useCallback, Fragment, useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import { Box, xcss } from '@atlaskit/primitives';

import type { TreeItem } from '@confluence/tree';
import type { EditorPropertyType } from '@confluence/content-utils/entry-points/EditorPropertyType';
import { ErrorDisplay } from '@confluence/error-boundary';
import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import {
	BLOG_TREE_EXPERIENCE,
	BLOG_TREE_PAGINATION_EXPERIENCE,
	ExperienceStart,
	ExperienceFailure,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';

import { BlogTreeComponent } from './BlogTreeComponent';
import { flattenTreeData, getBlogpostNodes, isBlogTreeTruncated } from './dataTransformers';
import { BlogTree as BlogTreeQuery } from './BlogTreeQuery.graphql';
import type { BlogTree as BlogTreeQueryType } from './__types__/BlogTree';
import { PAGE_SIZE, BLOG_STATUSES } from './treeParameters';
import { BlogTreeErrorComponent } from './BlogTreeErrorComponent';
import { useBlogTreeStore } from './BlogTreeStore';
import { BLOG_TREE_METRIC } from './perf.config';

type BlogTreeRootComponentProps = {
	spaceKey: string;
	flags: FlagsStateContainer;
	pageTreeFinishedLoading: boolean;
	isNav4Enabled?: boolean;
};

const emptyStateTextWrapper = xcss({
	paddingLeft: 'space.150',
	color: 'color.text.subtlest',
});

const i18n = defineMessages({
	noBlogsEmptyStateDescription: {
		id: 'blog-tree.empty-state-description',
		defaultMessage: 'No blogs in this space',
		description: 'Message when blog tree is empty',
	},
	paginateErrorFlagDescription: {
		id: 'blog-tree.paginate.failed.description',
		defaultMessage:
			'We encountered a network error while loading more blogs. Refresh the page and try again. If this keeps happening, contact your space administrator.',
		description:
			'An error message that displays in a flag to let the user know that there was a problem while loading more content into their tree',
	},
	paginateErrorFlagTitle: {
		id: 'blog-tree.paginate.failed.title',
		defaultMessage: 'Something went wrong',
	},
});

export interface BlogTreeItem extends TreeItem {
	data: {
		title?: string;
		status?: string;
		blank?: boolean;
		emoji?: string;
		emojiFromProperties?: string;
		webui?: string;
		editorVersion?: EditorPropertyType;
		isSelected?: boolean;
		createdYear?: string;
	};
}

export const BlogTreeRootComponent = withFlags(
	({ flags, spaceKey, pageTreeFinishedLoading, isNav4Enabled }: BlogTreeRootComponentProps) => {
		const experienceTracker = useContext(ExperienceTrackerContext);
		const intl = useIntl();
		const [fetchMoreError, setFetchMoreError] = useState<ApolloError | undefined>(undefined);
		const [shouldForceRefetch, _] = useBlogTreeStore();

		const { error, loading, data, fetchMore, networkStatus, refetch } = useQuery<BlogTreeQueryType>(
			// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			BlogTreeQuery,
			{
				errorPolicy: 'all',
				variables: {
					spaceKey,
					first: PAGE_SIZE,
					statuses: BLOG_STATUSES,
				},
				notifyOnNetworkStatusChange: true,
			},
		);

		useEffect(() => {
			void refetch();
		}, [shouldForceRefetch, refetch]);

		const fetchMoreBlogs = useCallback(async () => {
			try {
				const endCursor = data?.content?.pageInfo?.endCursor || '';
				const { errors } = await fetchMore({
					variables: { endCursor },
					updateQuery: (previousQueryResult, { fetchMoreResult }) => {
						if (!fetchMoreResult) return previousQueryResult;

						return {
							content: {
								nodes: [
									...getBlogpostNodes(previousQueryResult),
									...getBlogpostNodes(fetchMoreResult),
								],
								__typename: 'PaginatedContentListWithChildren',
								pageInfo: fetchMoreResult?.content?.pageInfo || null,
							},
						};
					},
				});
				experienceTracker.succeed({
					name: BLOG_TREE_PAGINATION_EXPERIENCE,
				});
				if (errors) {
					// errors is of type GraphQLError[] but we need type ApolloError / Error for ErrorDisplay.
					// throwing the error here converts it into an ApolloError
					throw errors;
				}
			} catch (e) {
				// fetchMore errors are not surfaced in initial useQuery "error"
				setFetchMoreError(e);
				void flags.showErrorFlag({
					title: intl.formatMessage(i18n.paginateErrorFlagTitle),
					description: intl.formatMessage(i18n.paginateErrorFlagDescription),
					isAutoDismiss: true,
				});
			}
		}, [fetchMore, data, flags, intl, experienceTracker]);

		if (data && data?.content?.nodes?.length === 0) {
			return (
				<Box xcss={emptyStateTextWrapper}>
					<FormattedMessage {...i18n.noBlogsEmptyStateDescription} />
				</Box>
			);
		}

		const blogTreeData = flattenTreeData(data ? data.content : null);

		return (
			<Fragment>
				<PageSegmentLoadStart metric={BLOG_TREE_METRIC} />
				<ExperienceStart name={BLOG_TREE_EXPERIENCE} />
				{error || fetchMoreError ? (
					<Fragment>
						{error && (
							<ErrorDisplay error={error}>
								<ExperienceFailure name={BLOG_TREE_EXPERIENCE} error={error} />
							</ErrorDisplay>
						)}
						{fetchMoreError && (
							<ErrorDisplay error={fetchMoreError}>
								<ExperienceFailure name={BLOG_TREE_PAGINATION_EXPERIENCE} error={fetchMoreError} />
							</ErrorDisplay>
						)}
						<BlogTreeErrorComponent />
					</Fragment>
				) : (
					<BlogTreeComponent
						spaceKey={spaceKey}
						blogTreeData={blogTreeData}
						shouldShowLoadMoreButton={data ? isBlogTreeTruncated(data.content) : false}
						fetchMoreBlogs={fetchMoreBlogs}
						networkStatus={networkStatus}
						loading={loading}
						pageTreeFinishedLoading={pageTreeFinishedLoading}
						isNav4Enabled={isNav4Enabled}
					/>
				)}
			</Fragment>
		);
	},
);
