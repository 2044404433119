import type { EmojiProvider, OptionalEmojiDescription } from '@atlaskit/emoji/types';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import { fg } from '@atlaskit/platform-feature-flags';

import { getDraftShareIdFromQueryParam } from '@confluence/route-manager/entry-points/getDraftShareIdFromQueryParam';

import { sendAnalyticsEvent } from './analytics';
import type { Content } from './emoji-title/__types__/EmojiTitleButton';
import type { EmojiTitleStateContainer } from './emoji-title/EmojiTitleStateContainer';
import type { EmojiStatusFieldName } from './__types__/EmojiStatusFieldName';
import { getEmojiProvider, getVersionNumber } from './transformer';
import { getEmojiTitleProperty } from './emoji-title/EmojiTitleQuery.graphql';
import { convertEmojiFieldNameToPropertyKey } from './useEmojiStatusFieldName';

export type GetEmojiArgs = {
	userId: string;
	cloudId: string;
	emojiId: string;
};

export const getEmojiFromEmojiId = async ({ userId, cloudId, emojiId }: GetEmojiArgs) => {
	const provider = await getEmojiProvider(cloudId, userId);
	if (provider) {
		const emoji = await provider.findById(emojiId);
		if (emoji) {
			return provider.loadMediaEmoji(emoji);
		}
	}

	return Promise.resolve(null);
};

export const invalidEmojiRegex = /([a-z\d_-]+):/;

type OnSelectEmojiArgs = {
	selectedEmoji: OptionalEmojiDescription;
	emojiProvider: EmojiProvider;
	onUpdateStartEmoji?: () => void;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	emojiTitleState: EmojiTitleStateContainer;
	onEmojiUpdated: () => Promise<any>;
	onUpdateEndEmoji?: () => void;
	updateContentPropertyMutation: any;
	contentId: string;
	componentName: string;
	isLivePage: boolean;
	emojiStatusFieldName: EmojiStatusFieldName;
};

let hasSetFavicon = false;
let previousFavicon: string | null = null;
const updateFaviconHref = (href: string) => {
	const favIcons = document.querySelectorAll("link[rel~='icon']");
	favIcons.forEach((linkEl) => {
		// remember default favicon on first change in case we need to revert
		if (!hasSetFavicon) {
			previousFavicon = (linkEl as HTMLLinkElement).href;
			hasSetFavicon = true;
		}

		(linkEl as HTMLLinkElement).href = href;
	});
};
export const maybeOverrideFaviconWithEmoji = (emoji: string | null) => {
	if (!fg('confluence_emoji_title_favicon')) {
		return;
	}

	if (!emoji) {
		if (hasSetFavicon && previousFavicon) {
			updateFaviconHref(previousFavicon);
		}
		return;
	}

	updateFaviconHref(emoji);
};

export const onSelectEmoji = async (
	{
		selectedEmoji,
		emojiProvider,
		onUpdateStartEmoji,
		createAnalyticsEvent,
		emojiTitleState,
		onEmojiUpdated,
		onUpdateEndEmoji,
		updateContentPropertyMutation,
		contentId,
		componentName,
		isLivePage,
		emojiStatusFieldName,
	}: OnSelectEmojiArgs,
	_event: any,
) => {
	onUpdateStartEmoji && onUpdateStartEmoji();

	sendAnalyticsEvent(createAnalyticsEvent, {
		eventType: 'sendUIEvent',
		element: 'button',
		action: 'clicked',
		actionSubject: 'emojiPicker',
		actionSubjectId: 'emoji',
		attributes: {
			emojiId: selectedEmoji?.id,
			inputMethod: 'title',
			isLivePage,
		},
	});

	const propertyKey = convertEmojiFieldNameToPropertyKey(emojiStatusFieldName);

	const loadedEmoji = await emojiProvider.loadMediaEmoji(selectedEmoji!, true);
	void emojiTitleState.setOpenSecondEmojiPicker(false);
	void emojiTitleState.setSelectedEmoji(loadedEmoji);

	return onEmojiUpdated().then(({ data }: any) => {
		const versionNumber = getVersionNumber(data, emojiStatusFieldName);
		const newVersionNumber = versionNumber ? versionNumber + 1 : 1;
		const emojiValue = selectedEmoji?.id?.replace(/['"]+/g, '') ?? '';

		return updateContentPropertyMutation({
			variables: {
				contentId,
				propertyKey,
				propertyValue: emojiValue,
				versionNumber: String(newVersionNumber),
			},
			update: (proxy: any) => {
				try {
					const cacheData = proxy.readQuery({
						query: getEmojiTitleProperty,
						variables: {
							contentId,
							status: ['current', 'draft'],
							skipPublishedFragment: true,
							draftShareId: getDraftShareIdFromQueryParam(),
						},
					}) as Content;

					if (cacheData.content.nodes[0][emojiStatusFieldName]) {
						cacheData.content.nodes[0][emojiStatusFieldName].nodes[0] = {
							...cacheData.content.nodes[0][emojiStatusFieldName].nodes[0],
							id: contentId,
							key: propertyKey,
							value: emojiValue,
							version: {
								number: newVersionNumber,
								__typename: 'Version',
							} as any,
						};
					}

					proxy.writeQuery({
						query: getEmojiTitleProperty,
						variables: {
							contentId,
							status: ['current', 'draft'],
							skipPublishedFragment: true,
							draftShareId: getDraftShareIdFromQueryParam(),
						},
						data,
					});
				} catch (error) {
					throw new Error(
						`${componentName} failed to write to cache while updating ${propertyKey} content property after user selecting emoji ${error.message}`,
					);
				}
			},
			optimisticResponse: {
				__typename: 'Mutation',
				experimentalUpdateContentProperty: {
					__typename: 'ExperimentalUpdateContentProperty',
					success: true,
					id: contentId,
					key: propertyKey,
					value: emojiValue,
				},
			},
		})
			.then(() => {
				onUpdateEndEmoji && onUpdateEndEmoji();
				void emojiTitleState.setSelectedEmoji(loadedEmoji);
			})
			.catch((error: any) => {
				throw new Error(
					`${componentName} failed to update ${propertyKey} content property after user selecting emoji ${error.message}`,
				);
			});
	});
};

type OnRemoveEmojiArgs = {
	onUpdateStartEmoji?: () => void;
	onUpdateEndEmoji?: () => void;
	onEmojiUpdated: () => Promise<any>;
	contentId: string;
	updateContentPropertyMutation: any;
	componentName: string;
	emojiStatusFieldName: EmojiStatusFieldName;
};
export const onRemoveEmoji = (
	_event: any,
	{
		onUpdateStartEmoji,
		onUpdateEndEmoji,
		onEmojiUpdated,
		contentId,
		updateContentPropertyMutation,
		componentName = '',
		emojiStatusFieldName,
	}: OnRemoveEmojiArgs,
) => {
	onUpdateStartEmoji && onUpdateStartEmoji();
	const propertyKey = convertEmojiFieldNameToPropertyKey(emojiStatusFieldName);

	return onEmojiUpdated().then(() => {
		return updateContentPropertyMutation({
			variables: {
				contentId,
				propertyKey,
				isDelete: true,
			},
			update: (proxy: any) => {
				try {
					const data = proxy.readQuery({
						query: getEmojiTitleProperty,
						variables: {
							contentId,
							status: ['current', 'draft'],
							skipPublishedFragment: true,
							draftShareId: getDraftShareIdFromQueryParam(),
						},
					}) as Content;

					data.content.nodes[0][emojiStatusFieldName] = {
						nodes: [],
						__typename: 'PaginatedJsonContentPropertyList',
					};

					proxy.writeQuery({
						query: getEmojiTitleProperty,
						variables: {
							contentId,
							status: ['current', 'draft'],
							skipPublishedFragment: true,
							draftShareId: getDraftShareIdFromQueryParam(),
						},
						data,
					});
				} catch (error) {
					throw new Error(
						`${componentName} failed to write to cache while updating content property after user removing emoji ${error.message}`,
					);
				}
			},
			optimisticResponse: {
				__typename: 'Mutation',
				experimentalUpdateContentProperty: {
					__typename: 'ExperimentalUpdateContentProperty',
					success: true,
				},
			},
		})
			.finally(() => {
				onUpdateEndEmoji && onUpdateEndEmoji();
			})
			.catch((error: any) => {
				throw new Error(
					`${componentName} failed to update ${propertyKey} content property after user removing emoji ${error.message}`,
				);
			});
	});
};

/**
 * https://unicode.org/reports/tr51/#EBNF_and_Regex
 * We use Extended_Pictographic instead of Emoji because Emoji considers non alpha characters like numbers (0-9) and
 * symbols ([]{}/>+=) to be emoji.
 */
export const emojiExp =
	/^(\p{RI}\p{RI}|\p{Extended_Pictographic}(\p{Emoji_Modifier}|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?(\u{200D}\p{Extended_Pictographic}(\p{Emoji_Modifier}|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?)*)/u;

export const removeEmojiFromTitle = (title: string | null | undefined) =>
	(title && title.replace(emojiExp, '').trimLeft()) || '';

export const getEmojiFromTitle = (title: string | null | undefined) =>
	(title && emojiExp.exec(title)?.[0]) || '';

/**
 * Transform a title and emoji from the server into their display versions
 * @param rawTitle string from Page or Content
 * @param rawPublishedEmoji string from content property value for published emoji
 * @param rawDraftEmoji string from content property value for draft emoji
 * @param status the status of the content
 * @param untitledDraftText translated string to be used for title if content is blank draft
 */
export const getTitleAndEmoji = (
	rawTitle: string | null = '',
	rawPublishedEmoji = '',
	rawDraftEmoji = '',
	status = '',
	untitledDraftText = '',
	getEmoji = true,
) => {
	const nativeEmoji = getEmoji ? getEmojiFromTitle(rawTitle) : '';
	let selectedEmoji = '';
	if (getEmoji) {
		selectedEmoji = status?.toLowerCase() === 'draft' ? rawDraftEmoji : rawPublishedEmoji;
	}
	const hasNativeEmoji = nativeEmoji.length > 0;
	const hasSelectedEmoji = selectedEmoji.length > 0;
	const emoji = hasSelectedEmoji ? selectedEmoji : nativeEmoji;
	let text = hasNativeEmoji && !hasSelectedEmoji ? removeEmojiFromTitle(rawTitle) : rawTitle;
	if (status === 'draft' && !text) {
		text = untitledDraftText;
	}
	return { text, emoji, selectedEmoji };
};
