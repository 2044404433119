import { createHook, createStore, createActionsHook } from 'react-sweet-state';
import type { Action } from 'react-sweet-state';

type BlogTreeStoreState = {
	shouldForceRefetch: boolean;
};
type BlogTreeStoreActions = {
	forceRefetchBlogTree: () => Action<BlogTreeStoreState, void, void>;
};

const initialState: BlogTreeStoreState = {
	shouldForceRefetch: false, // this is just a boolean that we toggle between false and true to force a refetch in blog tree
};

const actions: BlogTreeStoreActions = {
	forceRefetchBlogTree:
		() =>
		({ setState, getState }) => {
			const currentState = getState();
			setState({ shouldForceRefetch: !currentState.shouldForceRefetch });
		},
};

const BlogTreeStore = createStore<BlogTreeStoreState, BlogTreeStoreActions>({
	initialState,
	actions,
});

export const useBlogTreeStore = createHook(BlogTreeStore);
export const useBlogTreeActions = createActionsHook(BlogTreeStore);
